import { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SelectOption } from '@clarke-energia/foton-v2';

import { HOME_PATH } from '@routers/constants';

import { colors } from '@utils/theme';

import { useUserInfo } from '@hooks/use-user-info';
import { useDownloadFiles } from '@hooks/download-files';
import { formatDateToAbbrMonthNameAndYear, formatDateToMonthNameAndYear } from '@utils/dayjs';
import { useExportElementAsImage } from '@hooks/export-as-image';
import { useAuthorizationHeaders } from '@hooks/use-authorization-headers';
import { useGetUnitEconomyByGroupId } from '@hooks/economy/get-unit-economy-by-group-id';
import { useGetGroupAccumulatedEconomyReportByGroupId } from '@hooks/economy/get-group-accumulated-economy-report-by-group-id';
import {
  IGeneralReportTable,
  IGeneralReportTableData,
  IMinimalGeneralReportTable,
  UnitEconomyReportDataResponse,
} from '@hooks/economy/types';

import Layout from '@components/templates/screen-layout';
import EmptyData from '@components/molecules/empty-data';
import PageSection from '@components/molecules/general/page-section';
import { HomeSkeleton } from '@components/molecules/skeleton/home-skeleton';
import { EconomyReportSkeleton } from '@components/molecules/skeleton/economy-report-skeleton';

import EconomyFilters from '@components/organisms/economy-report/filter';
import EconomyCards from '@components/organisms/economy-report/economy-cards';
import EconomyBarCharts from '@components/organisms/economy-report/economy-charts';
import UnitEconomyCards from '@components/organisms/economy-report/unit-economy-cards';
import EconomyComparison from '@components/organisms/economy-report/economy-comparison';

import configuration from '@config';

import {
  buildEconomyCardDataFromGroupAccumulatedReport,
  buildEconomyCardDataFromSummary,
  buildEconomyCardDataFromUnitEconomyReport,
  buildEconomyCardDataFromUnitReport,
  getUnitEconomyCardsData,
} from './helpers/economy-cards';

import {
  buildEconomyChartDataFromAllHistory,
  buildEconomyChartDataFromUnitEconomyReport,
  buildEconomyChartDataFromUnitId,
  buildEconomyChartDataFromUnitReports,
} from './helpers/economy-chart';

import {
  Unit,
  EconomyFilterState,
  UnitSummaryEconomyReport,
  EconomyReportIconCardData,
  EconomyChartData,
  CostChartData,
} from './types';

import {
  buildUnitFilter,
  defaultUnitFilter,
  buildPeriodFilter,
  getUnitsFromReport,
  defaultPeriodFilter,
  getFreeMarketDataTable,
  getConventionalMarketDataTable,
  getUnitEconomyReportByIdAndPeriod,
  getUnitAccumulatedEconomyReportById,
  getGroupAccumulatedEconomyReportByPeriod,
  buildCostChartData,
} from './functions';

import style from './style.module.css';

const EconomyReport: React.FC = () => {
  const { isFetchingUser } = useUserInfo();
  const { id, period } = useParams();
  const { user } = useUserInfo();

  const HOST = configuration.REPORT_API_HOST + '/api/v1/economy_report_excel';
  const headersWithAuthorization = useAuthorizationHeaders();

  const {
    getUnitsEconomyReportsByGroupId,
    data: { unitsEconomyReports, rawHistoryUnitEconomyReport },
    loading: getUnitsEconomyReportsByGroupIdLoading,
  } = useGetUnitEconomyByGroupId();

  const {
    getGroupAccumulatedEconomyReportByGroupId,
    parsedData: { parsedGroupAccumulatedEconomyReport, parsedUnitAccumulatedSummaryReport },
  } = useGetGroupAccumulatedEconomyReportByGroupId();

  const downloadExcel = useDownloadFiles;
  const exportAsImage = useExportElementAsImage();

  const [units, setUnits] = useState<Unit[]>([]);
  const [filter, setFilter] = useState<EconomyFilterState>({
    unit: id,
    period: period ? formatDateToMonthNameAndYear(period, 'YYYY-MM') : undefined,
  } as EconomyFilterState);

  const [filterUnits, setFilterUnits] = useState<SelectOption[]>([defaultUnitFilter]);
  const [filterPeriods, setFilterPeriods] = useState<SelectOption[]>([defaultPeriodFilter]);

  const [showEconomyComparison, setShowEconomyComparison] = useState<boolean>(false);

  const [selectedReport, setSelectedReport] = useState<UnitEconomyReportDataResponse | null>(null);

  const [freeMarketTables, setFreeMarketTables] =
    useState<Array<IGeneralReportTableData<IGeneralReportTable | IMinimalGeneralReportTable>>>();
  const [conventionalMarketTables, setConventionalMarketTables] =
    useState<Array<IGeneralReportTableData<IGeneralReportTable | IMinimalGeneralReportTable>>>();

  const [unitsEconomyData, setUnitsEconomyData] = useState<UnitSummaryEconomyReport[]>([]);
  const [economyDataCardsData, setEconomyDataCardsData] = useState<EconomyReportIconCardData | null>(null);

  const [costChartData, setCostChartData] = useState<CostChartData>({
    title: 'Custo de Energia',
    freeMarket: {
      name: 'Custo no Mercado Livre',
      data: [],
      color: colors.primary[1],
    },
    conventionalMarket: {
      name: 'Custo no Mercado Cativo',
      data: [],
      color: colors.gray[10],
    },
    economy: {
      name: 'Economia',
      data: [],
      color: colors.yellow[20],
    },
    labels: [],
  });
  const [economyChartData, setEconomyChartData] = useState<EconomyChartData | null>(null);

  const costChartRef = useRef<HTMLDivElement>(null);
  const economyChartRef = useRef<HTMLDivElement>(null);
  const economyComparisonRef = useRef<HTMLDivElement>(null);

  const hasData = useMemo(() => {
    return unitsEconomyReports && Object.keys(unitsEconomyReports).length > 0;
  }, [unitsEconomyReports]);

  const isValidFilterPeriodButAll = useMemo(() => {
    return filter.period && filter.period != 'Todo';
  }, [filter]);

  const isValidFilterUnitButAll = useMemo(() => {
    return filter.unit && filter.unit != 'Todas';
  }, [filter]);

  const getUnitsEconomyData = useMemo(() => {
    const reports = isValidFilterPeriodButAll
      ? unitsEconomyReports[filter.period ?? '']
      : parsedUnitAccumulatedSummaryReport;
    return getUnitEconomyCardsData(reports);
  }, [isValidFilterPeriodButAll, filter, unitsEconomyReports, parsedUnitAccumulatedSummaryReport]);

  const getCostChartData = () => {
    const newCostChartData = { ...costChartData };

    const chartData = buildCostChartData(unitsEconomyReports, rawHistoryUnitEconomyReport, filter.unit);

    newCostChartData.economy.data = chartData.economy;
    newCostChartData.freeMarket.data = chartData.freeMarket;
    newCostChartData.conventionalMarket.data = chartData.conventionalMarket;
    newCostChartData.labels = Object.keys(unitsEconomyReports).map((e) => {
      const date = unitsEconomyReports[e][0].date;
      return formatDateToAbbrMonthNameAndYear(date);
    });

    setCostChartData(newCostChartData);
  };

  const exportCharts = (type: 'economy' | 'cost') => {
    const element = type == 'economy' ? economyChartRef.current : costChartRef.current;

    const trigger = element?.querySelector('button');
    trigger?.classList.add('hidden');

    exportAsImage(element as HTMLElement, type == 'economy' ? 'gráfico-economia' : 'gráfico-custo-energia');
    trigger?.classList.remove('hidden');
  };

  useEffect(() => {
    if (user?.group) {
      getUnitsEconomyReportsByGroupId({
        variables: {
          id: user.group.id,
        },
      });

      getGroupAccumulatedEconomyReportByGroupId({
        variables: {
          groupId: user.group.id,
        },
      });
    }
  }, [user]);

  useEffect(() => {
    if (!getUnitsEconomyReportsByGroupIdLoading) {
      setUnits(getUnitsFromReport(rawHistoryUnitEconomyReport));

      setFilterPeriods(buildPeriodFilter(unitsEconomyReports));

      setEconomyChartData(buildEconomyChartDataFromAllHistory(rawHistoryUnitEconomyReport));
      setEconomyDataCardsData(buildEconomyCardDataFromGroupAccumulatedReport(parsedGroupAccumulatedEconomyReport));

      setUnitsEconomyData(getUnitsEconomyData);
      getCostChartData();
    }
  }, [getUnitsEconomyReportsByGroupIdLoading]);

  useEffect(() => {
    setFilterUnits(buildUnitFilter(units));
  }, [units]);

  useEffect(() => {
    if (selectedReport) {
      setFreeMarketTables(getFreeMarketDataTable(selectedReport));
      setConventionalMarketTables(getConventionalMarketDataTable(selectedReport));
    } else {
      setFreeMarketTables([]);
      setConventionalMarketTables([]);
    }
  }, [selectedReport]);

  useEffect(() => {
    setUnitsEconomyData(getUnitsEconomyData);

    setShowEconomyComparison(false);

    if (isValidFilterPeriodButAll && isValidFilterUnitButAll) {
      const reportByUnitPeriod = getUnitEconomyReportByIdAndPeriod(
        filter.unit ?? '',
        filter.period ?? '',
        unitsEconomyReports,
        rawHistoryUnitEconomyReport,
      );

      if (reportByUnitPeriod) {
        setEconomyChartData(buildEconomyChartDataFromUnitEconomyReport(reportByUnitPeriod));
        setSelectedReport(reportByUnitPeriod);
        setEconomyDataCardsData(buildEconomyCardDataFromUnitEconomyReport(reportByUnitPeriod));
      }

      setShowEconomyComparison(true);
    } else if (isValidFilterPeriodButAll) {
      const reportByPeriod = getGroupAccumulatedEconomyReportByPeriod(
        filter.period ?? '',
        parsedGroupAccumulatedEconomyReport,
      );
      if (reportByPeriod) {
        setEconomyChartData(
          buildEconomyChartDataFromUnitReports(unitsEconomyReports[filter.period ?? ''], rawHistoryUnitEconomyReport),
        );
        setEconomyDataCardsData(buildEconomyCardDataFromSummary(reportByPeriod));
      }
    } else if (isValidFilterUnitButAll) {
      const unitAccumulatedReport = getUnitAccumulatedEconomyReportById(
        filter.unit ?? '',
        parsedUnitAccumulatedSummaryReport,
      );

      if (unitAccumulatedReport) {
        setEconomyChartData(buildEconomyChartDataFromUnitId(filter.unit ?? '', rawHistoryUnitEconomyReport));
        setEconomyDataCardsData(buildEconomyCardDataFromUnitReport(unitAccumulatedReport));
      }

      getCostChartData();
    } else {
      setEconomyChartData(buildEconomyChartDataFromAllHistory(rawHistoryUnitEconomyReport));
      setEconomyDataCardsData(buildEconomyCardDataFromGroupAccumulatedReport(parsedGroupAccumulatedEconomyReport));

      getCostChartData();
    }
  }, [filter]);

  const exportEconomyComparison = (value: string) => {
    if (value == 'PNG') {
      exportAsImage(economyComparisonRef.current as HTMLElement, 'relatorio-comparativo-economia');
    }
  };

  if (isFetchingUser) {
    return (
      <Layout.Content
        header={{
          title: 'Relatório de economia',
          displayDesktop: true,
        }}
      >
        <HomeSkeleton />
      </Layout.Content>
    );
  }

  return (
    <Layout.Content
      header={{
        title: 'Relatório de economia',
        displayDesktop: true,
        breadCrumbLinks: [
          {
            label: 'Home',
            url: HOME_PATH,
          },
          {
            label: 'Relatório de economia',
            url: '#',
          },
        ],
      }}
    >
      {getUnitsEconomyReportsByGroupIdLoading ? (
        <EconomyReportSkeleton />
      ) : !hasData && !getUnitsEconomyReportsByGroupIdLoading ? (
        <EmptyData description="A migração para o Mercado Livre ainda não foi concluída. Você encontrará os relatórios de economia assim que concluir o processo de migração." />
      ) : (
        <>
          <EconomyFilters
            units={{
              options: filterUnits,
              onChange: (unit) => {
                setFilter((prev) => {
                  return { ...prev, unit: unit == 'Todas' ? null : unit };
                });
              },
              value: filter.unit ?? 'Todas',
              emptyValue: 'Todas',
            }}
            periods={{
              options: filterPeriods,
              onChange: (period) => {
                setFilter((prev) => {
                  return { ...prev, period: period == 'Todo' ? null : period };
                });
              },
              value: filter.period ?? 'Todo',
              emptyValue: 'Todo',
            }}
          />

          <div className={'grid grid-cols-3 col-span-full gap-8'}>
            {economyDataCardsData && (
              <PageSection>
                <EconomyCards className={style.container} economyReportIconCardData={economyDataCardsData} />
              </PageSection>
            )}

            <PageSection>
              <EconomyBarCharts
                id="economy-cost-charts-container"
                className={style.container}
                economyChart={{
                  ref: economyChartRef,
                  title: 'Economia',
                  freeMarket: {
                    title: 'Custo no Mercado Livre',
                    value: economyChartData?.freeMarket ?? '',
                    color: 'bg-primary-30',
                  },
                  conventionalMarket: {
                    title: 'Custo no Mercado Cativo',
                    color: 'bg-gray-10',
                    value: economyChartData?.conventionalMarket ?? '',
                  },
                  economy: economyChartData?.economy ?? 0,
                  onChange(value) {
                    if (value == 'PNG') {
                      exportCharts('economy');
                    }
                  },
                }}
                costChart={{
                  ref: costChartRef,
                  title: costChartData?.title ?? '',
                  dashedSeries: [costChartData.economy],
                  labels: costChartData?.labels ?? [],
                  series: [costChartData?.conventionalMarket, costChartData?.freeMarket],
                  onChange(value) {
                    if (value == 'PNG') {
                      exportCharts('cost');
                    }
                    if (value == 'EXCEL') {
                      if (!filter.unit || filter.unit == 'Todas') {
                        const groupId = user?.group?.id || '';
                        downloadExcel(
                          `${HOST}/group/${groupId}`,
                          'relatorio-economia-grupo-comercial.xlsx',
                          headersWithAuthorization,
                        );
                      } else {
                        const unitId = filter.unit ?? '';
                        downloadExcel(
                          `${HOST}/unit/${unitId}`,
                          'relatorio-economia-unidade-consumidora.xlsx',
                          headersWithAuthorization,
                        );
                      }
                    }
                  },
                }}
              />
            </PageSection>

            {!filter.unit && unitsEconomyData.length > 0 && (
              <PageSection>
                <UnitEconomyCards
                  className={style.container}
                  unitEconomyCardsItems={unitsEconomyData}
                  onIconClick={(id) => {
                    setFilter((prev) => {
                      return { ...prev, unit: id };
                    });
                  }}
                />
              </PageSection>
            )}

            {showEconomyComparison && (
              <PageSection>
                <EconomyComparison
                  report={selectedReport}
                  tables={{
                    freeMarket: freeMarketTables,
                    conventionalMarket: conventionalMarketTables,
                  }}
                  content={{
                    ref: economyComparisonRef,
                  }}
                  exportComponent={{
                    onChange: exportEconomyComparison,
                  }}
                />
              </PageSection>
            )}
          </div>
        </>
      )}
    </Layout.Content>
  );
};

export default EconomyReport;
