import ecologicalSustainability from '@assets/ecological-sustainability.jpeg';

import NuggetInfo from './large-nugget';
import style from './style.module.css';

const SustainabilityCertificates: React.FC = () => {
  return (
    <>
      <div className={style.imageContainer}>
        <img className={style.image} src={ecologicalSustainability} />
      </div>

      <div className={style.contentContainer}>
        <p>
          Você faz parte de <strong>30%</strong> das empresas no mercado livre que consomem energia de fontes
          renováveis!
        </p>
        <p>
          As fontes de energia não renováveis impactam diretamente o meio ambiente com a emissão de gases do efeito
          estufa. Consumir energia no Mercado Livre através da Clarke Energia significa garantir que seu consumo seja
          proveniente de fontes renováveis, como biomassa, eólica, solar ou de Pequena Central Hidrelétrica (PCH).
        </p>

        <div className={style.nuggetsContainer}>
          {/* TODO: In foton-v2, nugget just with info (centr.) */}
          <NuggetInfo
            tooltip={{
              content: `A biomassa é uma fonte de energia renovável feita a partir de materiais orgânicos, como resíduos agrícolas e florestais.
                  Pode ser queimada ou convertida em biocombustíveis, ajudando a reduzir emissões de carbono e a dependência de combustíveis fósseis.`,
              side: 'bottom',
              icon: {
                icon: 'InformationCircleIcon',
              },
            }}
            icon={{
              icon: 'BeakerIcon',
              extraClassNames: style.icon,
            }}
            info="Biomassa"
          />
          <NuggetInfo
            tooltip={{
              content: `A energia eólica é gerada a partir do vento, que movimenta turbinas para produzir eletricidade.
                  É uma fonte renovável e sustentável, com baixo impacto ambiental, amplamente utilizada em parques eólicos. A energia eólica contribui para a diversificação da matriz energética e redução das emissões de gases de efeito estufa.`,
              side: 'bottom',
              icon: {
                icon: 'InformationCircleIcon',
              },
            }}
            icon={{
              icon: 'CloudIcon',
              extraClassNames: style.icon,
            }}
            info="Eólica"
          />
          <NuggetInfo
            tooltip={{
              content: `A energia solar é gerada a partir da radiação do sol, usando painéis solares para produzir eletricidade ou aquecer água.
                   É uma fonte renovável, limpa e sustentável, com baixo impacto ambiental.`,
              side: 'bottom',
              icon: {
                icon: 'InformationCircleIcon',
              },
            }}
            icon={{
              icon: 'SunIcon',
              extraClassNames: style.icon,
            }}
            info="Solar"
          />
          <NuggetInfo
            tooltip={{
              content: `As Pequenas Centrais Hidrelétricas são usinas hidrelétricas de pequeno porte e menor potência.
                  Elas utilizam a força e pressão da água para gerar energia.
                  Por serem menores, elas não necessitam estocar água, aproveitando o nível das cheias dos rios.`,
              side: 'bottom',
              icon: {
                icon: 'InformationCircleIcon',
              },
            }}
            icon={{
              icon: 'BoltIcon',
              extraClassNames: style.icon,
            }}
            info="PCH"
          />
        </div>
      </div>
    </>
  );
};

export default SustainabilityCertificates;
