import React, { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';

import { Unit } from './types';
import { GET_GROUP_UNITS_QUERY } from './queries';

export const useGetUnitsByGroup = () => {
  const [units, setUnits] = React.useState<Unit[]>([]);
  const [getUnits, { data, loading, error }] = useLazyQuery<{
    group: {
      units: Unit[];
    };
  }>(GET_GROUP_UNITS_QUERY, {
    fetchPolicy: 'network-only',
  });

  function getUnitsHandler(groupId: string) {
    return getUnits({ variables: { groupId } });
  }

  useEffect(() => {
    if (data) {
      setUnits(data.group.units);
    }
  }, [data]);

  return {
    units,
    loading,
    error,
    getUnitsByGroup: getUnitsHandler,
  };
};
