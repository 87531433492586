import { ReactNode, useRef } from 'react';
import { Carousel } from '@clarke-energia/foton-v2';

import StepProgressCard, { StepProgressCardProps } from '@components/molecules/step-progress-card';
import MultipleCardsContainer from '@components/atoms/layouts/multiple-cards-container';
import { ITopic, MigrationState } from '@hooks/migration-report/types';
import { getMigrationState } from '@pages/migration-reports/helper';

import style from './style.module.css';

const mapStepStatus = {
  [MigrationState.IN_PROGRESS]: 'ongoing',
  [MigrationState.DONE]: 'success',
  [MigrationState.OVERDUE]: 'warning',
  neutral: 'neutral',
};

const getStepStatus = (conclusionPercentage: number, dueDate: string | null): StepProgressCardProps['status'] => {
  const state = getMigrationState(conclusionPercentage, dueDate);
  if (state === MigrationState.IN_PROGRESS && conclusionPercentage === 0) {
    // @ts-expect-error - Is correct mapping
    return mapStepStatus.neutral;
  }
  // @ts-expect-error - Is correct mapping
  return mapStepStatus[state];
};

const CarouselMobileHandler = ({ children }: { children: ReactNode[] }) => {
  const controlSizeRef = useRef<HTMLDivElement>(null);

  const isMobile = window.innerWidth < 768;
  const size = controlSizeRef?.current?.parentElement?.offsetWidth;

  if (isMobile) {
    return (
      <div
        style={{
          width: size ? `${size - 10}px` : '90vw',
        }}
        ref={controlSizeRef}
      >
        <Carousel slidesToShow={1.8} useArrows>
          {children}
        </Carousel>
      </div>
    );
  }

  return (
    <MultipleCardsContainer childMinWidth={188} gap={32}>
      {children}
    </MultipleCardsContainer>
  );
};

interface StepsProgressHeaderProps {
  topics: ITopic[];
  selectedTopicId?: string;
  onCardClick?: (topicId: string) => void;
}

const StepsProgressHeader = (props: StepsProgressHeaderProps) => {
  return (
    <div className={style.container}>
      <CarouselMobileHandler>
        {props.topics.map((topic, index) => {
          return (
            <div
              key={topic.thirdPartyId}
              onClick={() => props.onCardClick?.(topic.thirdPartyId)}
              className="w-full cursor-pointer"
            >
              <div className="w-full">
                <StepProgressCard
                  step={index + 1}
                  description={topic.title}
                  progress={topic.conclusionPercentage * 100}
                  status={getStepStatus(topic.conclusionPercentage, topic.dueDate)}
                  focus={props.selectedTopicId === topic.thirdPartyId}
                />
              </div>
            </div>
          );
        })}
      </CarouselMobileHandler>
    </div>
  );
};

export default StepsProgressHeader;
