export interface Unit {
  id: string;
  name: string;
  submarket: SubmarketEnum;
  totalConsumptionVolume: number;
}

export enum SubmarketEnum {
  NORTH = 'NORTH',
  NORTHEAST = 'NORTHEAST',
  SOUTH = 'SOUTH',
  SOUTHEAST = 'SOUTHEAST',
}
