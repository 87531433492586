import React from 'react';
import { NuggetInfo } from '@clarke-energia/foton-v2';

import style from './style.module.css';

export interface ContractPricesProps {
  visible: boolean;
  prices: { year: number; amount: string }[];
}

const EnergyContractPrices: React.FC<ContractPricesProps> = ({ prices, visible }) => {
  return (
    <div className={visible ? style.mainContainer : 'hidden'}>
      <div className={style.nuggetTextContainer}>
        <NuggetInfo
          title=""
          icon={{
            icon: 'CurrencyDollarIcon',
            extraClassNames: 'bg-gray-10 p-3',
          }}
          info=""
        />
        <span className={style.priceLabel}>{`Preço Base ${'(R$/MWh)'}`}</span>
      </div>
      <div className="flex">
        {prices?.map(({ year, amount }) => (
          <div key={year}>
            <div className={style.yearContainer}>
              <p className={style.year}>{year}</p>
            </div>
            <div>
              <p className={style.amount}>{amount}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EnergyContractPrices;
