import { gql } from '@apollo/client';

export const GET_GROUP_UNITS_QUERY = gql`
  query Group($groupId: ID!) {
    group(id: $groupId) {
      units {
        id
        name
        submarket
        totalConsumptionVolume
      }
    }
  }
`;
