import moment from 'moment';
import numeral from '@utils/numeral';
import { Units } from '@contexts/files/types';
import { DocType } from './constants/common';

export const upperFirstLetter = (str: string) => str[0]?.toUpperCase() + str.slice(1).toLowerCase();

export const capitalizeWords = (str: string): string => {
  return str
    .split(' ')
    .map((word) => upperFirstLetter(word))
    .join(' ');
};

export const capitalize = (str: string, units: Units[] = []) => {
  if (parseInt(str)) {
    return str;
  }

  if (DocType.some((type: Record<string, string>) => type.value === str.replaceAll(' ', '_'))) {
    const doc = DocType.find((type) => type.value === str.replaceAll(' ', '_'));
    return doc?.label ?? '';
  }

  if (units.length > 0 && units.some((type: Units) => type.id === str)) {
    const unit = units.find((type) => type.id === str);
    return unit?.name ?? '';
  }

  return upperFirstLetter(str);
};

const dateOptions: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: '2-digit' };
export const formatDate = (date: Date) =>
  new Intl.DateTimeFormat('pt-BR', dateOptions).format(date).replace(/\.? de /g, ' ');

export const formatNumber = (num: number, options?: Intl.NumberFormatOptions): string => {
  const opts = options ? options : { style: 'decimal', useGrouping: true, maximumFractionDigits: 2 };
  return new Intl.NumberFormat('pt-BR', opts).format(num);
};

export const formatCurrency = (num: number, options?: Intl.NumberFormatOptions): string => {
  const opts = options ? options : { style: 'currency', currency: 'BRL', useGrouping: true };
  return new Intl.NumberFormat('pt-BR', opts).format(num);
};

export const formatCNPJ = (num: string): string => {
  const formatted: string[] = [];
  [...num].forEach((char, index) => {
    formatted.push(char);
    if (index === 1 || index == 4) formatted.push('.');
    if (index === 7) formatted.push('/');
    if (index === 11) formatted.push('-');
  });
  return formatted.join('');
};

export const formatCPF = (num: string): string => {
  const formatted: string[] = [];
  [...num].forEach((char, index) => {
    formatted.push(char);
    if (index === 2 || index == 5) formatted.push('.');
    if (index === 8) formatted.push('-');
  });
  return formatted.join('');
};

export const formatMonthAndYearDate = (date: string) => {
  const monthAndYear = new Date(date).toLocaleString('pt-br', { month: 'long', year: 'numeric' });
  return `${monthAndYear.charAt(0).toLocaleUpperCase()}${monthAndYear.slice(1).replace(' de ', '/')}`;
};

export const formatFilePath = (filePath: string) => {
  const currentItem = filePath.split('/')[0];
  if (currentItem.includes('.')) {
    return currentItem;
  }
  return upperFirstLetter(currentItem.replaceAll('_', ' '));
};

export const formatStringToUseInURL = (text: string) => {
  return text
    .replace(/\s+/g, '')
    .replace(/[áàãâä]/g, 'a')
    .replace(/[éèêë]/g, 'e')
    .replace(/[íìîï]/g, 'i')
    .replace(/[óòõôö]/g, 'o')
    .replace(/[úùûü]/g, 'u')
    .replace(/[ç]/g, 'c')
    .toLowerCase();
};

export const formatAsKWh = (value: number | undefined): string => {
  return value ? numeral(value).format('0,0.00').concat(' kWh') : '-';
};

export const getLastDayMonth = () => {
  const date = new Date();
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  return formatDate(lastDay);
};

export const formatStringDate = (date: string) => {
  return moment(date).format('DD/MM/YYYY');
};

export const formatButtonUrl = (destinationPath: string) => {
  return `${window.location.href}${destinationPath.replace('/', '')}`;
};
