import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { twMerge } from 'tailwind-merge';
import { BarChart, BarChartProps, ModalComponent, NuggetInfo } from '@clarke-energia/foton-v2';

import { Contract } from '@hooks/get-contracts/types';
import { useDownloadFiles } from '@hooks/download-files';
import EnergyContractPrices from '@components/molecules/contract/energy-contrat-prices/prices';
import ChartContainer from '@components/molecules/contract/chartConainer/chart-container';

import { energyNuggetInfo, managementNuggetInfo, NuggetInfoType, ContentKeys, formatUnitsNames } from './helper';

import style from './sytle.module.css';

export interface ConsumptionChart {
  labels: BarChartProps['labels'];
  series: BarChartProps['series'];
  lines: BarChartProps['lines'];
}

export interface ModalProps {
  cardType: 'ENERGY' | 'MANAGEMENT';
  active: boolean;
  chart?: ConsumptionChart;
  content: Omit<Contract, 'type' | 'active'>;
  loadingChart: boolean;

  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ContractModal: React.FC<ModalProps> = ({ cardType, active, isOpen, setIsOpen, content, chart, loadingChart }) => {
  const isEnergyContract = cardType === 'ENERGY';
  const nuggetInfoData = isEnergyContract ? energyNuggetInfo : managementNuggetInfo;

  return (
    <>
      <ModalComponent
        className={style.modalContainer}
        isOpen={isOpen}
        setOpen={setIsOpen}
        title={{
          text: `Contrato de ${isEnergyContract ? 'fornecimento' : 'gestão'} - ${formatUnitsNames(content.unitsNames)}`,
          extraClassName: style.modalTitle,
        }}
        buttonsTitle={
          content.file
            ? [
                {
                  icon: 'ArrowDownTrayIcon',
                  kind: 'primary',
                  size: 'small',
                  onClick: () => useDownloadFiles(content?.file?.link || '', content.file?.name || ''),
                },
              ]
            : []
        }
        tagTitle={{
          color: 'primaryLight',
          kind: 'icon',
          icon: { icon: 'CheckBadgeIcon', solid: true },
          label: `Contrato ${active ? 'ativo' : 'inativo'}`,
        }}
      >
        {loadingChart ? (
          <Skeleton className={style.energyContractHeight} />
        ) : (
          <div className={twMerge(style.contentContainer, isEnergyContract ? style.energyContractHeight : '')}>
            {Object.keys(nuggetInfoData).map((key) => {
              const typedKey = key as ContentKeys;
              const info = content[typedKey];
              return (
                <NuggetInfo
                  title={nuggetInfoData[key as keyof NuggetInfoType].title}
                  icon={{
                    icon: nuggetInfoData[key as keyof NuggetInfoType].icon,
                    extraClassNames: 'bg-gray-10 p-4',
                  }}
                  info={info || ''}
                  key={nuggetInfoData[key as keyof NuggetInfoType].title}
                  sizes={{
                    info: 10,
                    title: 8,
                  }}
                />
              );
            })}

            <EnergyContractPrices prices={content.prices || []} visible={isEnergyContract} />

            {isEnergyContract && chart && (
              <ChartContainer
                title="Consumo Real x Consumo Contratado x Flex"
                tooltipContent="Info grafico"
                className={style.chartContainer}
              >
                <BarChart
                  title=""
                  series={chart.series}
                  labels={chart.labels}
                  lines={chart.lines}
                  dashedSeries={[]}
                  options={{
                    legend: true,
                  }}
                />
              </ChartContainer>
            )}
          </div>
        )}
      </ModalComponent>
    </>
  );
};

export default ContractModal;
