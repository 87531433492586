import React, { HTMLAttributes } from 'react';
import { Card, IconButton } from '@clarke-energia/foton-v2';

import MultipleCardsContainer from '@components/atoms/layouts/multiple-cards-container';

import { UnitSummaryEconomyReport } from '@pages/economy-reports/types';

import style from './style.module.css';

export interface UnitEconomyCardsProps extends HTMLAttributes<HTMLDivElement> {
  unitEconomyCardsItems: UnitSummaryEconomyReport[];
  onIconClick: (id: string) => void;
}

const UnitEconomyCards: React.FC<UnitEconomyCardsProps> = ({ unitEconomyCardsItems, onIconClick, ...props }) => {
  return (
    <div {...props}>
      {unitEconomyCardsItems.map(({ unit, economy, energy }) => (
        <Card.Root key={unit.name} className={style.cardRoot}>
          <Card.Header className={style.cardHeader}>
            <Card.Title title={unit.name} />
            <IconButton icon="EyeIcon" onClick={() => onIconClick(unit.id)} />
          </Card.Header>
          <Card.Content>
            <Card.InfoList direction="row" shouldWrap={true} className="gap-3 2xl:gap-0">
              <MultipleCardsContainer childMinWidth={250} gap={24}>
                <Card.Info
                  className={style.cardInfo}
                  type="primary"
                  title="Economia"
                  message={economy}
                  icon="CurrencyDollarIcon"
                />
                <Card.Info
                  className={style.cardInfo}
                  type="primary"
                  title="Custo"
                  message={energy.cost}
                  icon="BanknotesIcon"
                />
                <Card.Info
                  className={style.cardInfo}
                  type="primary"
                  title="Consumo"
                  message={energy.consumption}
                  icon="LightBulbIcon"
                />
              </MultipleCardsContainer>
            </Card.InfoList>
          </Card.Content>
        </Card.Root>
      ))}
    </div>
  );
};

export default UnitEconomyCards;
