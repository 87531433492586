import React from 'react';

import { BarChart, BarChartProps, LineChart, LineChartProps } from '@clarke-energia/foton-v2';

import ChartContainer from '@components/molecules/contract/chartConainer/chart-container';
import sytle from './sytle.module.css';

interface ChartSectionProps {
  barChart: {
    series: BarChartProps['series'];
    labels: BarChartProps['labels'];
  };
  lineChart: {
    labels: LineChartProps['labels'];
    series: LineChartProps['series'];
  };
}

const ChartsSection: React.FC<ChartSectionProps> = ({ barChart, lineChart }) => {
  return (
    <>
      <ChartContainer className={sytle.chartContainer} title="Volume contratado ao longo dos anos">
        <BarChart
          title=""
          stack
          series={barChart.series}
          labels={barChart.labels}
          lines={[]}
          dashedSeries={[]}
          options={{
            legend: true,
          }}
        />
      </ChartContainer>
      <ChartContainer className={sytle.chartContainer} title="Preço contratado ao longo dos anos">
        <LineChart
          title=""
          series={lineChart.series}
          labels={lineChart.labels}
          lines={[]}
          options={{
            legend: true,
          }}
        />
      </ChartContainer>
    </>
  );
};

export default ChartsSection;
