import React from 'react';
import { IconButtonProps } from '@clarke-energia/foton-v2';
import { twMerge } from 'tailwind-merge';

import { useDownloadFiles } from '@hooks/download-files';
import CardComponent from '@components/molecules/card';
import { Contract } from '@hooks/get-contracts/types';

import style from './sytle.module.css';

type ContractCard = Pick<Contract, 'type' | 'active' | 'unitsNames' | 'period' | 'contracted' | 'file'>;

export interface CardSectionProps extends ContractCard {
  handleOpenModal: () => void;
}

const buttonsHeader = (file: CardSectionProps['file'], handleOpenModal: () => void) => {
  const iconButtons: IconButtonProps[] = [
    { kind: 'secondary', icon: 'EyeIcon', size: 'small', onClick: handleOpenModal },
  ];

  if (file) {
    const downloadBtn: IconButtonProps = {
      kind: 'secondary',
      icon: 'ArrowDownTrayIcon',
      size: 'small',
      onClick: () => useDownloadFiles(file.link, file.name),
    };
    iconButtons.push(downloadBtn);
  }

  return iconButtons;
};

const ContractCardsSection: React.FC<CardSectionProps> = ({
  type,
  active,
  contracted,
  period,
  unitsNames,
  file,
  handleOpenModal,
}) => {
  const isEnergyCard = type === 'ENERGY';
  const cardStyle = isEnergyCard ? style.cardEnergyActive : style.cardManagementActive;
  const cardActiveStyle = active ? cardStyle : style.cardInative;

  return (
    <div className={style.cardContainer}>
      <CardComponent
        title={`Contrato ${isEnergyCard ? 'energia' : 'gestão'}`}
        tooltip={{
          icon: {
            icon: 'CheckBadgeIcon',
            extraClassNames: twMerge(style.iconCard, cardActiveStyle),
            solid: true,
          },
          content: active ? 'Contrato ativo' : 'Contrato inativo',
          side: 'top',
        }}
        headerButtons={buttonsHeader(file, handleOpenModal)}
      >
        <p className={style.cardText}>{`Contratada: ${isEnergyCard ? contracted : 'Clarke'}`}</p>
        <p className={style.cardText}>{`Vigência: ${period}`}</p>
        <p className={style.cardText}>{`Unidades Consumidoras: ${unitsNames.join(',')}`}</p>
        <p className={style.cardText}>{`Status: ${active ? 'Ativa' : 'Inativo'}`}</p>
      </CardComponent>
    </div>
  );
};

export default ContractCardsSection;
