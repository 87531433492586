import { triggerAmplitudeButtonClickedEvent } from '@services/event-tracking/events-trigger';

import { EnergyMarketType, IEnergyMarketType } from './types';

export const getTooltipMessage = (marketType: IEnergyMarketType) => {
  return marketType === EnergyMarketType.ACL
    ? 'Esse é o valor da sua conta de luz no Mercado Livre. Clicando em ”Ver Mais” é possível ver o detalhamento da fatura de distribuição e de fornecimento.'
    : 'Simulamos como a sua conta de luz seria se estivesse no Mercado Cativo. A partir desse número chegamos quanto foi a economia. É possível ver o detalhamento clicando em "Ver Mais".';
};

export const getMarketName = (marketType: IEnergyMarketType) => {
  return marketType === EnergyMarketType.ACL ? 'Livre' : 'Cativo';
};

export const triggerButtonEvent = (buttonText: string, destinationPath?: string) => {
  return triggerAmplitudeButtonClickedEvent('Relatório de Economia Acumulado', buttonText, destinationPath);
};
