import { HeroIcon, IconName, NuggetInfo, Tag } from '@clarke-energia/foton-v2';
import { twMerge } from 'tailwind-merge';

import { useIsMobile } from '@hooks/is-mobile';

import style from './style.module.css';

const HeaderIcon = ({ icon, extraClassNames }: { icon: IconName; extraClassNames: string }) => {
  return (
    <div className={twMerge(style.iconContainer, extraClassNames)}>
      <HeroIcon icon={icon} extraClassNames={extraClassNames} />
    </div>
  );
};

export interface NuggetTagHeaderProps {
  icon: {
    icon: IconName;
    extraClassNames?: string;
  };
  title: string;
  tag: {
    icon: IconName;
    label: string;
    className?: string;
  };
}

const NuggetTagHeader = (props: NuggetTagHeaderProps) => {
  const isMobile = useIsMobile();

  return (
    <div className={style.container}>
      <NuggetInfo
        info={props.title}
        icon={<HeaderIcon icon={props.icon.icon} extraClassNames={props.icon.extraClassNames || ''} />}
        sizes={{
          info: isMobile ? 14 : 16,
          title: 32,
        }}
      />
      <Tag
        label={props.tag.label}
        kind="icon"
        className={twMerge(style.tag, props.tag.className)}
        icon={{
          icon: props.tag.icon,
        }}
      />
    </div>
  );
};

export default NuggetTagHeader;
