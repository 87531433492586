import { ProgressBar } from '@clarke-energia/foton-v2';
import { twMerge } from 'tailwind-merge';

import style from './style.module.css';

const mapColors = {
  success: {
    bg: 'bg-[#E6F9DC]',
    bar: '#005931',
    text: 'text-[005931]',
    focus: 'bg-[#55EC8F]',
  },
  warning: {
    bg: 'bg-[#FFD4CC]',
    bar: '#991700',
    text: 'text-[#991700]',
    focus: 'bg-orange-30',
  },
  ongoing: {
    bg: 'bg-white',
    bar: '#2E2E2E',
    text: 'text-gray-80',
    focus: 'bg-gray-10',
  },
  neutral: {
    bg: 'bg-white',
    bar: '#2E2E2E',
    text: 'text-gray-80',
    focus: 'bg-gray-10',
  },
};

export interface StepProgressCardProps {
  step: number;
  description: string;
  status: 'success' | 'warning' | 'ongoing' | 'neutral';
  progress: number;
  focus?: boolean;
}

const StepProgressCard = (props: StepProgressCardProps) => {
  return (
    <div className={twMerge(mapColors[props.status].bg, style.container, props.focus && mapColors[props.status].focus)}>
      <div className={twMerge(style.content, mapColors[props.status].text, 'font-semibold')}>
        <span>{props.step}.</span>
        <p>{props.description}</p>
      </div>
      <ProgressBar value={props.progress} labelPosition="right" size="large" color={mapColors[props.status].bar} />
    </div>
  );
};

export default StepProgressCard;
