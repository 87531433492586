import React from 'react';
import {
  Card,
  HeroIcon,
  IconButton,
  IconButtonProps,
  IconProps,
  TooltipComponent,
  TooltipContentProps,
} from '@clarke-energia/foton-v2';

interface CardProps {
  title: string;
  icon?: IconProps;
  tooltip?: TooltipContentProps;
  headerButtons?: IconButtonProps[];
  children: React.ReactNode;
}

const CardComponent = ({ title, icon, tooltip, headerButtons, children }: CardProps): JSX.Element => {
  return (
    <Card.Root>
      <Card.Header className="pb-4">
        {tooltip && <TooltipComponent {...tooltip} />}
        {icon && <HeroIcon {...icon} />}
        <Card.Title title={title} props={{ className: 'mr-auto' }} />
        {headerButtons &&
          headerButtons.map((button, idx) => <IconButton key={`btn-${button.icon}-${idx}`} {...button} />)}
      </Card.Header>
      <Card.Content>{children}</Card.Content>
    </Card.Root>
  );
};

export default CardComponent;
