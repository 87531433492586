import numeral from 'numeral';
import { BarChartProps, LineChartProps } from '@clarke-energia/foton-v2';

import { formatDateFromString } from '@utils/dayjs';

import { colors } from '@utils/theme';

import {
  Contract,
  EnergyContract,
  EnergyTypeEnum,
  GuaranteeTypeEnum,
  ManagementContract,
  PriceChartData,
  ReadjustmentIndexEnum,
} from './types';
import { upperFirstLetter } from '@utils/text';

const DEFAULT_INPUT_FORMAT = 'YYYY-MM-DD HH:mm:ss';
const OUTPUT_FORMAT = 'MM/YYYY';
const LONG_MONTH_NAME_OUTPUT_FORMAT = 'MMMM/YYYY';
const HOURS_PER_MONTH = 720;

export const formatEnergyContracts = (energyContracts: EnergyContract[]): Contract[] => {
  return energyContracts.map((contract) => {
    const initialPeriodStr = formatDateFromString(contract.startSupplyDate, DEFAULT_INPUT_FORMAT, OUTPUT_FORMAT);
    const finalPeriodStr = formatDateFromString(contract.endSupplyDate, DEFAULT_INPUT_FORMAT, OUTPUT_FORMAT);
    const readjustmentBaseDateStr = contract.readjustmentBaseDate
      ? formatDateFromString(contract.readjustmentBaseDate, DEFAULT_INPUT_FORMAT, LONG_MONTH_NAME_OUTPUT_FORMAT)
      : '';

    return {
      type: 'ENERGY',
      active: contract.isActive,
      period: `${initialPeriodStr} - ${finalPeriodStr}`,
      contracted: contract.trader.name,
      unitsNames: contract.units.map(({ name }) => name),
      energyType: EnergyTypeEnum[contract.energyType],
      prices: formatPrices(contract.economy, contract.contractType),
      upperFlexibility: contract.upperFlexibility / 100,
      lowerFlexibility: contract.upperFlexibility / 100,
      flexibility: formatFlexibilityStr(contract.upperFlexibility, contract.lowerFlexibility),
      readjustmentDate: upperFirstLetter(readjustmentBaseDateStr),
      readjustmentType: contract.readjustmentIndex ? ReadjustmentIndexEnum[contract.readjustmentIndex] : '',
      guarantee:
        contract.guaranteeType !== 'OTHER' ? GuaranteeTypeEnum[contract.guaranteeType] : contract.otherGuaranteeType,
      volumePerYear: contract.fixedContractedVolume
        ? formatFixedVolumePerContractYear(contract.fixedContractedVolume, contract.economy)
        : formatPerContractYear(contract.contractedVolumePerYear),
      file: contract.energyContractAttachments.length
        ? {
            name: contract.energyContractAttachments[0].fileName,
            link: contract.energyContractAttachments[0].url,
          }
        : undefined,
    };
  });
};

export const formatManagementContracts = (managementContracts: ManagementContract[]): Contract[] => {
  return managementContracts.map((contract) => {
    return {
      type: 'MANAGEMENT',
      active: contract.isActive,
      contracted: 'Clarke',
      unitsNames: contract.units.map(({ name }) => name),
      readjustmentDate: contract.readjustmentBaseDate ?? '',
      readjustmentType: contract.readjustmentIndex ? ReadjustmentIndexEnum[contract.readjustmentIndex] : '',
      file: contract.attachments.length
        ? {
            name: contract.attachments[0].fileName,
            link: contract.attachments[0].url,
          }
        : undefined,
      period: contract.signatureDate
        ? formatDateFromString(contract.signatureDate, DEFAULT_INPUT_FORMAT, OUTPUT_FORMAT)
        : '',
    };
  });
};

const formatFlexibilityStr = (upper: number, lower: number) => {
  const upperPercentage = numeral(upper / 100).format('0%');
  const lowerPercentage = numeral(lower / 100).format('0%');
  if (upper == lower) return `+/-${upperPercentage}`;

  return `+${upperPercentage} / -${lowerPercentage}`;
};

const formatPrices = (economy: EnergyContract['economy'], contractType: EnergyContract['contractType']) => {
  return economy.map(({ year, amount }) => ({
    year,
    amount: contractType === 'FIXED_PRICE' ? numeral(amount).format('$ 0,0.00') : numeral(amount / 100).format('0%'),
    rawAmount: amount,
  }));
};

const formatFixedVolumePerContractYear = (
  fixedVolume: number,
  economy: EnergyContract['economy'],
): Contract['volumePerYear'] =>
  economy.map(({ year }) => ({ year, amountMWm: fixedVolume, amountMWh: fixedVolume * HOURS_PER_MONTH }));

const formatPerContractYear = (volumePerYear: EnergyContract['contractedVolumePerYear']): Contract['volumePerYear'] =>
  volumePerYear.map(({ year, amount }) => ({ year, amountMWm: amount, amountMWh: amount * HOURS_PER_MONTH }));

export const volumeMWmChartData = (input: Contract[]): BarChartProps => {
  const allYearsSet = new Set<number>();
  const series = [] as BarChartProps['series'];

  input.forEach((item) => {
    item.volumePerYear?.forEach((v) => allYearsSet.add(v.year));
  });

  const allYears = Array.from(allYearsSet).sort((a, b) => a - b);
  const labels = allYears.map((year) => `${year}`);

  input.forEach((item, idx) => {
    const yearToAmountMap = new Map<number, number>();
    item.volumePerYear?.forEach((v) => {
      yearToAmountMap.set(v.year, v.amountMWm);
    });

    const data = allYears.map((year) => yearToAmountMap.get(year) ?? 0);

    series.push({
      name: item.contracted,
      data: data,
      color: idx === 0 ? colors.primary[1] : colors.primary[2],
    });
  });

  return { labels, series };
};

export const priceChartDataFormated = (input: Contract[]): PriceChartData => {
  const allYearsSet = new Set<number>();
  const series = [] as LineChartProps['series'];

  input.forEach((item) => {
    item.prices?.forEach((v) => allYearsSet.add(v.year));
  });

  const allYears = Array.from(allYearsSet).sort((a, b) => a - b);
  const labels = allYears.map((year) => `${year}`);

  input.forEach((item, idx) => {
    const yearToAmountMap = new Map<number, number>();
    item.prices?.forEach((v) => {
      yearToAmountMap.set(v.year, v.rawAmount);
    });

    const data = allYears.map((year) => yearToAmountMap.get(year) ?? 0);

    series.push({
      name: `Preço ${item.contracted} (R$/MWh)`,
      values: data,
      color: idx === 0 ? colors.primary[1] : colors.primary[2],
    });
  });

  return { labels, series };
};
