import React from 'react';
import { Select } from '@clarke-energia/foton-v2';

import { Unit } from '@hooks/get-units/types';

const OPTIONS = {
  '300': {
    label: '100 MWh - 300 MWh',
    min: 100,
    max: 300,
  },
  '500': {
    label: '301 MWh - 500 MWh',
    min: 301,
    max: 500,
  },
  '700': {
    label: '501 MWh - 700 MWh',
    min: 501,
    max: 700,
  },
  '1000': {
    label: '701 MWh - 1000 MWh',
    min: 701,
    max: 1000,
  },
  '1001': {
    label: 'Mais de 1000 MWh',
    min: 1001,
    max: Infinity,
  },
};

interface ConsumptionFilterProps {
  units: Unit[];
  onChange: (unitNames: string[] | null) => void;
}
const getUnits = (units: Unit[], option?: '300' | '500' | '700' | '1001') => {
  if (!option) return null;
  const validUnits = units.filter(
    (unit) => unit.totalConsumptionVolume >= OPTIONS[option].min && unit.totalConsumptionVolume <= OPTIONS[option].max,
  );
  return validUnits.map((unit) => unit.name);
};

const ConsumptionFilter: React.FC<ConsumptionFilterProps> = ({ units, onChange }) => {
  return (
    <Select.Root>
      <Select.Input
        options={[
          {
            label: 'Volume',
            // @ts-expect-error - implement in foton v2
            value: undefined,
          },
          ...Object.entries(OPTIONS).map(([value, { label }]) => ({
            label,
            value,
          })),
        ]}
        onChange={(value) => onChange(getUnits(units, value as '300' | '500' | '700' | '1001'))}
        prependIcon="AdjustmentsHorizontalIcon"
        appendIcon={null}
        triggerClassName="w-[190px]"
        value={undefined}
      />
    </Select.Root>
  );
};

export default ConsumptionFilter;
