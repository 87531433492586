import React from 'react';
import { twMerge } from 'tailwind-merge';
import { Header, HeaderProps } from '@clarke-energia/foton-v2';

import Sidebar from '@components/molecules/sidebar';
import SidebarMobile from '@components/molecules/menu-sidebar-mobile';

import style from './style.module.css';
import { useIsMobile } from '@hooks/is-mobile';

interface HeaderLayoutProps extends HeaderProps {
  displayDesktop: boolean;
}

interface LayoutContentProps {
  header: HeaderLayoutProps;
  children: React.ReactNode;
}

const LayoutContent: React.FC<LayoutContentProps> = (props: LayoutContentProps) => {
  const isMobile = useIsMobile();

  return (
    <div className={style.headerContainer}>
      <Header
        {...props.header}
        breadCrumbLinks={isMobile ? [] : props.header.breadCrumbLinks}
        className={twMerge(props.header.className, style.headerPadding, props.header.displayDesktop ? '' : 'md:hidden')}
      >
        <div className={style.headerItemsContainer}>
          {props.header.children}
          <SidebarMobile />
        </div>
      </Header>

      <main className={style.contentContainer}>{props.children}</main>
    </div>
  );
};

interface LayoutRootProps {
  children: React.ReactNode;
}
const LayoutRoot: React.FC<LayoutRootProps> = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className={style.container}>
      <Sidebar />

      {children}
    </div>
  );
};

export default {
  Root: LayoutRoot,
  Content: LayoutContent,
};
