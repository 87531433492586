import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import StepsProgressHeader from '@components/organisms/migrations/migration-steps-progress-header';
import { UnitMigrationReportSkeleton } from '@components/molecules/skeleton/unit-migration-report-skeleton';
import Layout from '@components/templates/screen-layout';
import ReturnHeader from '@components/molecules/return-header';
import ExportButton from '@components/molecules/button/export-button';
import MigrationTasks from '@components/organisms/migrations/migration-tasks';

import { IUnitsReport } from '@hooks/migration-report/types';
import { useGetMigrationReportByGroup } from '@hooks/migration-report/get-migration-report-by-group';
import { useUserInfo } from '@hooks/use-user-info';
import { useIsMobile } from '@hooks/is-mobile';

import { HOME_PATH, MIGRATION_REPORT_PATH } from '@routers/constants';

import UnitFilter from './unit-filter';
import style from './style.module.css';

const UnitMigrationDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { user, isFetchingUser } = useUserInfo();
  const {
    getMigrationReportByGroupHandler,
    migrationReportByGroup,
    loading: loadingMigrationReports,
  } = useGetMigrationReportByGroup();

  const [unitMigrationReport, setUnitMigrationReport] = useState<IUnitsReport>();
  const [selectedTopic, setSelectedTopic] = useState<string>();

  const exportRef = useRef<HTMLDivElement>(null);

  const isMobile = useIsMobile();

  const getTopic = (topicId: string) => {
    return unitMigrationReport?.topics?.find((topic) => topic.thirdPartyId === topicId);
  };

  useEffect(() => {
    if (user?.group) {
      getMigrationReportByGroupHandler(user.group.id);
    }
  }, [user]);

  useEffect(() => {
    if (!migrationReportByGroup?.units?.length) {
      return;
    }
    const unitMigrationReportByReportId = migrationReportByGroup?.units?.find(
      (unit) => unit.thirdPartyId === params.unitReportId,
    );
    if (!unitMigrationReportByReportId) {
      navigate(MIGRATION_REPORT_PATH);
    }
    setUnitMigrationReport(unitMigrationReportByReportId);
    if (unitMigrationReportByReportId?.topics?.length) {
      setSelectedTopic(unitMigrationReportByReportId.topics[0].thirdPartyId);
    }
  }, [migrationReportByGroup]);

  if (loadingMigrationReports || isFetchingUser) {
    return (
      <Layout.Content
        header={{
          title: isMobile ? 'Migração' : 'Migração para o Mercado Livre',
          displayDesktop: true,
          breadCrumbLinks: [
            {
              label: 'Home',
              url: HOME_PATH,
            },
            {
              label: 'Migração para o Mercado Livre',
              url: MIGRATION_REPORT_PATH,
            },
            {
              label: unitMigrationReport?.nickname || '',
              url: '',
            },
          ],
        }}
      >
        <UnitMigrationReportSkeleton />
      </Layout.Content>
    );
  }

  return (
    <Layout.Content
      header={{
        title: isMobile ? 'Migração' : `Migração para o Mercado Livre - ${unitMigrationReport?.nickname || ''}`,
        displayDesktop: true,
        children: (
          <ExportButton exportName={`relatorio-de-migração-${unitMigrationReport?.nickname}`} exportRef={exportRef} />
        ),
        breadCrumbLinks: [
          {
            label: 'Home',
            url: HOME_PATH,
          },
          {
            label: 'Migração para o Mercado Livre',
            url: MIGRATION_REPORT_PATH,
          },
          {
            label: unitMigrationReport?.nickname || '',
            url: '',
          },
        ],
      }}
    >
      <div ref={exportRef}>
        <div className={style.filter}>
          <div>
            <ReturnHeader returnPath={MIGRATION_REPORT_PATH} />
          </div>

          {unitMigrationReport && (
            <UnitFilter
              units={migrationReportByGroup?.units || []}
              selectedUnitId={unitMigrationReport?.thirdPartyId || ''}
              onChange={(unitId) => navigate(MIGRATION_REPORT_PATH + `/${unitId}`)}
            />
          )}
        </div>

        <div>
          <StepsProgressHeader
            topics={unitMigrationReport?.topics || []}
            selectedTopicId={selectedTopic}
            onCardClick={(topicId) => setSelectedTopic(topicId)}
          />
        </div>

        <div className="p-0 rounded-medium">
          <p className={style.taskHead}>Documentação necessária</p>
          {selectedTopic &&
            getTopic(selectedTopic)?.tasks.map((task, index) => <MigrationTasks key={index} tasks={[task]} />)}
        </div>
      </div>
    </Layout.Content>
  );
};

export default UnitMigrationDetails;
