import React, { useState } from 'react';

import { HOME_PATH, SUSTAINABILITY_PATH } from '@routers/constants';

import { checkYearAfterNow, getOneYearLater } from '@utils/dayjs';

import { useUserInfo } from '@hooks/use-user-info';
import { useContractByGroup } from '@hooks/get-contracts/use-contracts-group';
import { Contract } from '@hooks/get-contracts/types';
import { useConsumptionReportsByGroup } from '@hooks/get-consumption-contracts/use-consumption-contracts-group';
import { ConsumptionReportGroupGraphQL } from '@hooks/get-consumption-contracts/types';
import { buildConsumptionChart, buildPeriods } from '@hooks/get-consumption-contracts/parser';

import Layout from '@components/templates/screen-layout';
import { HomeSkeleton } from '@components/molecules/skeleton/home-skeleton';
import EmptyData from '@components/molecules/empty-data';
import ChartsSection from '@components/organisms/contracts/charts/charts';
import ContractCardsSection from '@components/organisms/contracts/card';
import TitleFilter, { ContractFilters } from '@components/molecules/contract/title-filter';
import ContractModal, { ConsumptionChart } from '@components/organisms/contracts/modal';

import style from './sytle.module.css';

const Contracts: React.FC = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [targetContract, setTargetContract] = useState<Contract | null>(null);
  const [filter, setFilter] = useState<ContractFilters>('ALL');
  const [chartData, setChartData] = useState<ConsumptionChart>();

  const { user } = useUserInfo();
  const { getContractByGroup, singleUnit, data, volumeChartData, priceChartData, error, loading } =
    useContractByGroup();
  const { getConsumptionsReportsHandler, loadingConsumptions } = useConsumptionReportsByGroup();

  const emptyDataContent = !data.length || error;

  React.useEffect(() => {
    if (user.group?.id) {
      const groupId = user.group?.id;

      getContractByGroup({
        variables: {
          groupId,
        },
      });
    }
  }, [user]);

  const filteredContracts = React.useMemo(() => {
    if (filter === 'ALL') return data;

    return data.filter(({ type }) => type === filter);
  }, [data, filter]);

  const handleChartData = (
    initialPeriod: string,
    finalPeriod: string,
    upperFlexibility: number,
    lowerFlexibility: number,
    consumptions: ConsumptionReportGroupGraphQL['getAllGroupsEconomyReportByGroupID'],
  ) => {
    const initialPeriodValue = checkYearAfterNow(initialPeriod)
      ? getOneYearLater(initialPeriod, 'MM/YYYY')
      : initialPeriod;
    const periods = buildPeriods(initialPeriodValue, finalPeriod);
    const data = buildConsumptionChart(consumptions, periods, upperFlexibility, lowerFlexibility);
    return data;
  };

  if (loading) {
    return (
      <Layout.Content
        header={{
          title: '',
          displayDesktop: true,
        }}
      >
        <HomeSkeleton />
      </Layout.Content>
    );
  }

  return (
    <Layout.Content
      header={{
        title: 'Contratos',
        displayDesktop: true,
        breadCrumbLinks: [
          {
            label: 'Home',
            url: HOME_PATH,
          },
          {
            label: 'Sustentabilidade',
            url: SUSTAINABILITY_PATH,
          },
        ],
      }}
    >
      {emptyDataContent ? (
        <EmptyData description="A migração para o Mercado Livre ainda não foi concluída. Você encontrará os contratos assim que concluir o processo de migração." />
      ) : (
        <div className={style.contentContainer}>
          <ChartsSection barChart={volumeChartData} lineChart={priceChartData} />

          <TitleFilter title="Contratos" onChange={(e: string) => setFilter(e as ContractFilters)} value={filter} />

          <div className={style.filterEmpty}>
            {!filteredContracts.length && (
              <EmptyData
                className="h-fit"
                title="Nenhum contrato encontrado"
                description="Não foi possivel filtrar nenhum contrato desse tipo."
              />
            )}
          </div>

          {filteredContracts.map((contract, idx) => {
            return (
              <ContractCardsSection
                key={`card-${contract.contracted}-${idx}`}
                {...contract}
                handleOpenModal={() => {
                  setTargetContract(contract);

                  if (contract.type === 'ENERGY') {
                    const groupId = user.group?.id ?? '';
                    const periods = contract.period.split('-');
                    const initialPeriod = periods[0].trim();
                    const finalPeriod = contract.active ? '' : periods[1].trim();
                    const upperFlex = contract.upperFlexibility ?? 0;
                    const lowerFlex = contract.lowerFlexibility ?? 0;

                    getConsumptionsReportsHandler(groupId, singleUnit).then((response) => {
                      const data = response ?? [];
                      const chartData = handleChartData(initialPeriod, finalPeriod, upperFlex, lowerFlex, data);
                      setChartData(chartData);
                    });
                  }

                  setModalOpen(!modalOpen);
                }}
              />
            );
          })}

          {targetContract && (
            <ContractModal
              cardType={targetContract.type}
              active={targetContract.active}
              content={{ ...targetContract }}
              chart={chartData}
              loadingChart={loadingConsumptions}
              isOpen={modalOpen}
              setIsOpen={setModalOpen}
            />
          )}
        </div>
      )}
    </Layout.Content>
  );
};

export default Contracts;
