import {
  NuggetInfo,
  NuggetInfoComponentProps as NuggetProps,
  TooltipComponent,
  TooltipContentProps,
} from '@clarke-energia/foton-v2';

import style from './style.module.css';

const LargeNugget = (props: NuggetProps) => {
  return (
    <span className={style.nuggetContainer}>
      <NuggetInfo {...props} tooltip={undefined} className={style.nugget} />
      <TooltipComponent {...(props.tooltip as TooltipContentProps)} />
    </span>
  );
};

export default LargeNugget;
