import React from 'react';
import { Select } from '@clarke-energia/foton-v2';
import { SubmarketEnum, Unit } from '@hooks/get-units/types';

interface SubmarketFilterProps {
  units: Unit[];
  onChange: (unitNames: string[] | null) => void;
}
const getUnits = (units: Unit[], submarket?: SubmarketEnum) => {
  if (!submarket) return null;
  const validUnits = units.filter((unit) => unit.submarket === submarket);
  return validUnits.map((unit) => unit.name);
};

const SubmarketFilter: React.FC<SubmarketFilterProps> = ({ units, onChange }) => {
  return (
    <Select.Root>
      <Select.Input
        options={[
          {
            label: 'Submercado',
            // @ts-expect-error - implementing in foton v2
            value: undefined,
          },
          {
            label: 'norte',
            value: SubmarketEnum.NORTH,
          },
          {
            label: 'nordeste',
            value: SubmarketEnum.NORTHEAST,
          },
          {
            label: 'sul',
            value: SubmarketEnum.SOUTH,
          },
          {
            label: 'sudeste',
            value: SubmarketEnum.SOUTHEAST,
          },
        ]}
        onChange={(value) => {
          onChange(getUnits(units, value as SubmarketEnum));
        }}
        prependIcon="AdjustmentsHorizontalIcon"
        appendIcon={null}
        triggerClassName="w-[160px]"
        value={undefined}
      />
    </Select.Root>
  );
};

export default SubmarketFilter;
