import dayjs from 'dayjs';
import { BarChartProps } from '@clarke-energia/foton-v2';

import { ConsumptionReportGroupGraphQL } from './types';

const MONTH_YEAR_FORMAT = 'MM/YYYY';

export const buildPeriods = (initialMonthYear: string, finalMonthYear?: string) => {
  const initialDate = dayjs(initialMonthYear, MONTH_YEAR_FORMAT);
  const periods = [];

  if (!finalMonthYear) {
    for (let i = 0; i < 12; i++) {
      periods.push(initialDate.add(i, 'month').format(MONTH_YEAR_FORMAT));
    }
  } else {
    const finalDate = dayjs(finalMonthYear, MONTH_YEAR_FORMAT);
    const totalMonths = finalDate.diff(initialDate, 'month') + 1;

    for (let i = 0; i < totalMonths; i++) {
      periods.push(initialDate.add(i, 'month').format(MONTH_YEAR_FORMAT));
    }
  }

  return periods;
};

export const buildConsumptionChart = (
  consumptions: ConsumptionReportGroupGraphQL['getAllGroupsEconomyReportByGroupID'],
  periods: string[],
  upperFlex: number,
  lowerFlex: number,
) => {
  const realConsumption: number[] = [];
  const contractedConsumptions: number[] = [];

  const contractedConsumption = consumptions[0]?.group?.unitsResume.totalContractedDemands ?? consumptions[0];
  const upperFlexTotal = upperFlex * contractedConsumption;
  const lowerFlexTotal = lowerFlex * contractedConsumption;

  const consumptionsData = buildConsumptions(consumptions);

  periods.forEach((period) => {
    const consumo = consumptionsData.get(period);

    if (consumo) {
      realConsumption.push(consumo.real);
      contractedConsumptions.push(contractedConsumption);
    } else {
      realConsumption.push(0);
      contractedConsumptions.push(contractedConsumption);
    }
  });

  return {
    labels: periods as BarChartProps['labels'],
    series: [
      {
        name: 'Consumo Real',
        data: realConsumption,
        color: '#19E58A',
      },
      {
        name: 'Consumo Contratado',
        data: contractedConsumptions,
        color: '#008C4C',
      },
    ] as BarChartProps['series'],
    lines: [
      { name: 'Flex', y: upperFlexTotal, color: '#FF6347' },
      { name: 'Flex', y: lowerFlexTotal, color: '#FF6347' },
    ],
  };
};

const buildConsumptions = (consumptions: ConsumptionReportGroupGraphQL['getAllGroupsEconomyReportByGroupID']) => {
  const consumptionMap = new Map<string, { real: number }>();

  consumptions.forEach((item) => {
    const dataItem = dayjs(item.date);
    const year = dataItem.year();
    const month = dataItem.month() + 1;

    const label = `${String(month).padStart(2, '0')}/${year}`;
    consumptionMap.set(label, {
      real: item.totalMonthlyEnergyConsumption,
    });
  });

  return consumptionMap;
};
