import { useLazyQuery } from '@apollo/client';
import { BarChartProps } from '@clarke-energia/foton-v2';

import { GET_CONTRACTS_BY_GROUP } from './queries';
import { Contract, ContractGroupGraphQL, PriceChartData } from './types';
import { formatEnergyContracts, formatManagementContracts, priceChartDataFormated, volumeMWmChartData } from './parser';

export const useContractByGroup = () => {
  const [getContractByGroup, { data, loading, error }] = useLazyQuery<ContractGroupGraphQL>(GET_CONTRACTS_BY_GROUP);
  let formatedData: Contract[] = [];
  let volumeChartData: BarChartProps = { labels: [], series: [] };
  let priceChartData: PriceChartData = { labels: [], series: [] };

  if (data) {
    const {
      group: { clarkeContracts, energyContracts },
    } = data;

    const energyContractsFormated = energyContracts.length ? formatEnergyContracts(energyContracts) : [];
    const managementeContractsFormated = clarkeContracts.length ? formatManagementContracts(clarkeContracts) : [];

    volumeChartData = volumeMWmChartData(energyContractsFormated);
    priceChartData = priceChartDataFormated(energyContractsFormated);

    formatedData = [...energyContractsFormated, ...managementeContractsFormated];
  }

  return {
    getContractByGroup,
    singleUnit: data?.group.units.length == 1,
    data: formatedData,
    volumeChartData,
    priceChartData,
    loading,
    error,
  };
};
