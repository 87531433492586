import React from 'react';
import { Card, TooltipComponent } from '@clarke-energia/foton-v2';

export interface ChartContainerProps {
  children: React.ReactNode;
  title: string;
  className: string;
  tooltipContent?: string;
}

const ChartContainer: React.FC<ChartContainerProps> = ({ title, tooltipContent, children, className = '' }) => {
  return (
    <Card.Root className={className}>
      <Card.Header>
        <Card.Title title={title} />
        {tooltipContent && (
          <TooltipComponent icon={{ icon: 'InformationCircleIcon' }} content={tooltipContent} side="top" />
        )}
      </Card.Header>
      {children}
    </Card.Root>
  );
};

export default ChartContainer;
