import { IconName } from '@clarke-energia/foton-v2';

export type ContentKeys =
  | 'contracted'
  | 'period'
  | 'energyType'
  | 'flexibility'
  | 'guarantee'
  | 'readjustmentType'
  | 'readjustmentDate'
  | 'managementAmount';

interface NuggetDetails {
  title: string;
  icon: IconName;
}

export type NuggetInfoType = Record<ContentKeys, NuggetDetails>;

export const energyNuggetInfo = {
  contracted: {
    title: 'Contratada',
    icon: 'BuildingOfficeIcon',
  },
  period: {
    title: 'Período de vigência',
    icon: 'CalendarIcon',
  },
  energyType: {
    title: 'Tipo de energia',
    icon: 'CalendarIcon',
  },
  flexibility: {
    title: 'Flexibilidade',
    icon: 'PresentationChartLineIcon',
  },
  guarantee: {
    title: 'Garantia',
    icon: 'ShieldCheckIcon',
  },
  readjustmentType: {
    title: 'Índice de reajuste',
    icon: 'ReceiptPercentIcon',
  },
  readjustmentDate: {
    title: 'Data base',
    icon: 'CalendarDaysIcon',
  },
} as NuggetInfoType;

export const managementNuggetInfo = {
  contracted: {
    title: 'Contratada',
    icon: 'BuildingOfficeIcon',
  },
  period: {
    title: 'Período de vigência',
    icon: 'CalendarIcon',
  },
  managementAmount: {
    title: 'Valor de gestão mensal',
    icon: 'CurrencyDollarIcon',
  },
  readjustmentType: {
    title: 'Índice de reajuste',
    icon: 'ReceiptPercentIcon',
  },
  readjustmentDate: {
    title: 'Data base',
    icon: 'CalendarDaysIcon',
  },
} as NuggetInfoType;

export const formatUnitsNames = (unitsNames: string[]) => {
  if (unitsNames.length === 0) return '';
  if (unitsNames.length === 1) return unitsNames[0];

  const allButLast = unitsNames.slice(0, -1).join(', ');
  const lastUnit = unitsNames[unitsNames.length - 1];
  return `${allButLast} e ${lastUnit}`;
};
